<template>
  <base-section
    id="theme-features"
    space="100"
  >
    <v-container>
      <base-section-heading title="Conheça nossos serviços" />
      <v-row
        class="md-6"
        justify="center"
      >
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
          lg="4"
        >
          <v-hover v-slot="{ hover }">
            <v-img
              height="350px"
              :src="feature.bg"
              aspect-ratio="1"
              class="grey lighten-2"
              @click.stop="feature.link ? openLink(feature.link) : showFeature(feature)"
            >
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover, 'white--text': true }"
                height="350px"
                :color="hover ? 'rgb(0, 0, 50, 0.5)' : 'rgb(0, 0, 0, 0.5)'"
              >
                <v-card-title class="text-h6 text-center">
                  <v-row
                    class="fill-height flex-column pa-5"
                    justify="space-between"
                  >
                    <v-img
                      height="90px"
                      contain
                      :src="feature.icon"
                    />
                    <p class="mt-2 subheading">
                      {{ feature.title }}
                    </p>

                    <div>
                      <ul
                        class="text-body-1"
                        style="list-style-type: none;"
                      >
                        <li
                          v-for="(itFe, ixitfe) in feature.items"
                          :key="ixitfe"
                        >
                          <span>{{ itFe }}</span>
                        </li>
                      </ul>
                    </div>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-img>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>

    <template>
      <v-dialog
        v-model="dialog"
        max-width="900"
        scrollable
      >
        <v-card v-if="selected">
          <v-card-title class="text-h5">
            {{ selected.title }}
          </v-card-title>
          <v-card-text
            class="text-body-1"
            v-html="selected.description"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Voltar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      dialog: null,
      selected: null,
      features: [

        // ADMINISTRAÇÃO
        {
          title: 'ADMINISTRAÇÃO',
          icon: require('@/assets/features/004-management.png'),
          bg: require('@/assets/curso-de-administração.jpg'),
          items: [
            'Coleta de orçamentos de obras e serviços que se  fizerem necessários',
            'Atendimento a fornecedores de materiais e serviços',
            'Gerenciamento do seguro de incêndio (obrigatório) e de responsabilidade civil',
          ],
          description: `<ul>
<li>Gerenciamento dos arquivos documentais</li>
  <li>Controle do cadastro de proprietários.</li>
  <li>Atendimento (pessoal/telefônico) de condôminos com fornecimento de informações, soluções de problemas, etc..</li>
  <li>Controle dos mandatos do Corpo Diretivo – Síndico e Conselho Consultivo.</li>
  <li>Serviço de malote para a retirada e entrega de correspondência no próprio Condomínio.</li>
  <li>Presença nas assembléias (qualificação e controle das presenças, esclarecimento de dúvidas e redação das respectivas atas)</li>
  <li>Assessoria às reuniões do Corpo Diretivo</li>
</ul>
<br>
<h2>Gestão da situação/manutenção dos equipamentos de segurança, levando-se em conta as normas do Corpo de Bombeiros, a legislação municipal (CONTRU) e as normas técnicas da ABNT, a saber:</h2>
<ul>
  <li>validade e renovação do AVCB – Atestado de Vistoria do Corpo de Bombeiros</li>
  <li>testes hidrostáticos dos extintores</li>
  <li>hidrantes e registro de recalque do Corpo de Bombeiros</li>
  <li>sinalização de segurança</li>
  <li>sistema de alarme</li>
  <li>sistema de iluminação de emergência</li>
  <li>grupo gerador de energia elétrica</li>
  <li>sistema de detecção de fumaça</li>
  <li>portas corta-fogo</li>
</ul>
<ul>
  <li>Transcrição das atas no Livro próprio e registro no Cartório de Títulos e Documentos</li>
  <li>Coleta de orçamentos de obras e serviços que se fizerem necessários</li>
  <li>Atendimento a fornecedores de materiais e serviços</li>
  <li>Elaboração das planilhas de concorrência e acompanhamento financeiro das obras e serviços</li>
  <li>Gerenciamento do seguro de incêndio (obrigatório) e de responsabilidade civil (controle do vencimento da apólice, coleta das propostas para a renovação, análise e encaminhamento das mesmas).</li>
  <li>sistema de sprinklers</li>
  <li>aplicação da legislação municipal referente aos deficientes físicos</li>
  <li>Controle/emissão de gráficos referente aos consumos de água/esgotos, de energia elétrica e de gás (são anexados às pastas mensais de prestações de contas).</li>
  <li>Administração de locações de espaços/áreas comuns do edifício (lajes de cobertura, fachadas, etc.)</li>
  <li>Serviço cobrado à parte na base de 5% da receita auferida pelo Condomínio.</li>
  <li>Controle e atualização do CNPJ do Condomínio. Eventual regularização decorrente de problemas.</li>
  <li>Incidentes em gestão anterior a nossa, será cobrada à parte, com preço submetido à prévia concordância do Síndico</li>
</ul>
<br>
<h2>Gestão dos contratos de conservação e de manutenção:</h2>
<ul>
  <li>elevadores</li>
  <li>porteiros eletrônicos, centrais telefônicas, antenas coletivas, áreas ajardinadas, portões automáticos</li>
  <li>conjunto moto-bombas, aquecimento central e ar condicionado central</li>
  <li>Gestão de contratos de prestação de serviços terceirizados:</li>
  <li>serviços de limpeza</li>
  <li>serviços de portaria</li>
  <li>Controle da manutenção das áreas de recreação: salão de festas, salão de jogos, piscinas,</li>
  <li>salão de ginástica, home theater, quadras poliesportivas, pistas de corrida, etc.</li>
  <li>Controle e cobrança dos alugueis pelo uso do salão de festas, de churrasqueira, gazebo, etc.</li>
</ul>
<ul>
  <li>Diligências junto à concessionárias de serviços públicos, cartórios e repartições públicas</li>
  <li>para obtenção de parcelamentos, certidões, etc.</li>
  <li>Controle dos acessos (emissão de crachás, livros de anotações, etc.).</li>
</ul>
`,
        },

        // CONTABILIDADE
        {
          title: 'CONTABILIDADE',
          icon: require('@/assets/features/003-accountant.png'),
          bg: require('@/assets/businessman-workig-e-usando-calculadora-com-laptop-na-mesa_34152-1467.jpg'),
          items: [
            'Elaboração e acompanhamento das previsões orçamentárias',
            'Elaboração do quadro de rateio das despesas',
            'Controle dos pagamentos das cotas condominiais',
          ],
          description: `
<ul>
  <li>Elaboração e acompanhamento das previsões orçamentárias.</li>
  <li>Elaboração do quadro de rateio das despesas.</li>
  <li>Emissão dos recibos/boletos bancários.</li>
  <li>Controle dos pagamentos das cotas condominias – conferência das baixas relacionadas pelo Banco.</li>
  <li>Cobrança administrativa de devedores – via carta e telefonemas.</li>
  <li>Emissão de Declaração Negativa de Débitos Condominiais.</li>
  <li>Contas a pagar</li>
  <li>Controle dos vencimentos e emissão de cheques para a liquidação dos pagamentos.</li>
  <li>Quitação dos pagamentos no próprio caixa, via bancos ou on line.</li>
  <li>Conciliação da conta bancária.</li>
  <li>Gestão tributária – cálculo para aplicação das alíquotas, retenção e recolhimento do PIS, COFINS, CSLL, ISS, INSS e IRF.</li>
  <li>Armazenamento de dados, inclusive controle da cumulatividade, para as informações anuais dos recolhimentos de PIS, COFINS e CSLL.</li>
  <li>Prestação de contas mensais – contas ordinária, fundo de reserva, fundo de obras, fundo de custeio do 13º salário, etc., com elaboração de demonstrativos de pagamentos de despesas e de recebimentos das cotas, dos devedores de cotas e balancete geral contendo a posição financeira do mês.</li>
  <li>Confecção das pastas de prestação de contas mensais.</li>
  <li>Controle da remessa e devolução das respectivas pastas.</li>
  <li>Elaboração de balancetes anuais para apresentação na assembleia Geral Ordinária.</li>
  <li>Disponibilização dos balancetes e demais demonstrativos financeiros na INTERNET.</li>
  <li>Gestão dos contratos coletivos de TV por assinatura – conferência das faturas, cobrança e controles das baixas.</li>
  <li>Gestão da conta corrente bancária exclusiva do Condomínio.</li>
  <li>Certidão Negativa de Débitos de Tributos e Contribuições Federais.</li>
</ul>`,
        },

        // JURÍDICO
        {
          title: 'JURÍDICO',
          icon: require('@/assets/features/001-compliant.png'),
          bg: require('@/assets/2019-01-10-ingles-juridico-entenda-o-vocabulario-da-area-802x506.jpg'),
          items: [
            'Elaboração de Convenção, Estatuto e Regimento Interno',
            'Consultoria tributária, fiscal e trabalhista',
            'Emissão de notificações extrajudiciais',
          ],
          description: `<ul>
<li>Atualização permanente da legislação que envolve a administração de Condomínios</li>
<li>Consultoria tributária, fiscal e trabalhista.</li>
<li>Emissão de notificações extra-judiciais e judiciais – serviço cobrado em separado.</li>
<li>Assessoria na alteração da Convenção de Condomínio – cobrado à parte.</li>
<li>Assessoria na elaboração de Regimentos Internos.</li>
<li>Assessoria na assinatura de contratos de prestação de serviços, de terceirização, de locação de espaços, etc..</li>
<li>Ações e acompanhamento da cobrança judicial dos devedores de cotas – serviço cobrado em separado.</li>
<li>Emissão de relatórios periódicos abrangendo o andamento das ações.</li>
<li>Acompanhamento das ações trabalhistas, cíveis e tributárias – serviço cobrado em separado.</li>
<li>Acompanhamento das ações propostas em Juizados Especiais – serviço cobrado em separado.</li>
<li>Acompanhamento das assembleias e reuniões do corpo administrativo do condomínio.</li>
<li>Ações junto a construtora - seja cobrança ou defeitos na edificação.</li>
</ul>`,
        },

        // RECURSOS HUMANOS
        {
          title: 'RECURSOS HUMANOS',
          icon: require('@/assets/features/009-team.png'),
          bg: require('@/assets/recursos-humanos.jpg'),
          items: [
            'Recrutamento e seleção de funcionários, com pesquisa cadastral',
            'Admissão e procedimentos de registro',
            'Contrato de experiência',
          ],
          description: `
<ul>
  <li>Recrutamento de funcionários</li>
  <li>Seleção de funcionários com pesquisa cadastral</li>
  <li>Admissão e procedimentos de registro</li>
  <li>Contrato de experiência.</li>
  <li>Atualização das fichas de registro dos funcionários</li>
  <li>Atualização das carteiras profissionais</li>
  <li>Formalização de advertências disciplinares</li>
  <li>Confecção/atualização do quadro de horário de trabalho</li>
  <li>Escala de revezamento e de férias – elaboração e controle</li>
  <li>Salário-família</li>
  <li>Elaboração da folha de pagamento</li>
  <li>Emissão dos comprovantes de pagamento dos salários</li>
  <li>Elaboração da folha de pagamento do 13º salário</li>
  <li>Elaboração e recolhimento das guias de INSS (GPS)</li>
  <li>Obtenção de CND – Certidão Negativa de Débitos com INSS</li>
  <li>Elaboração e recolhimento das guias de FGTS – GFIP e SEFIP</li>
  <li>Obtenção de CRF – Certificado de Regularidade de Situação do FGTS perante à Caixa Econômica Federal.</li>
  <li>Conectividade social – inscrição e acompanhamento junto à Caixa Econômica Federal</li>
  <li>Retenção e recolhimento de contribuições sindicais – patronal e dos empregados</li>
  <li>PIS – cadastramento de novos funcionários</li>
  <li>PIS – elaboração das guias e recolhimento</li>
  <li>Aquisição e controle de uniformes</li>
  <li>Rescisões dos contratos de trabalho – planilha de cálculo, emissão de recibos e guias</li>
  <li>Homologação das rescisões dos contratos no Sindicato de classe ou na DRT (reembolso de despesas objeto de cobrança em separado).</li>
  <li>RAIS – Relação Anual de Informações Sociais (serviço especial que é cobrado em separado com base no Referencial de Serviços Especiais da (AABIC).</li>
  <li>Recolhimento de Imposto de Renda retido na fonte dos funcionários.</li>
  <li>DIRF – Declaração de Imposto de Renda retido na fonte (cobrado à parte com base no Referencial da AABIC).</li>
  <li>Vale alimentação/refeição.</li>
  <li>Controle, aquisição e distribuição de vales transporte.</li>
  <li>EPI – Atendimento à NR-6.</li>
</ul>
<b>Nossos colaboradores são avaliados por psicóloga especializada na contratação e manutenção de profissionais variados.</b>
`,
        },

        // SÍNDICO PROFISSIONAL
        {
          title: 'SÍNDICO PROFISSIONAL',
          icon: require('@/assets/features/002-settings.png'),
          bg: require('@/assets/Síndico-Profissional-Remuneração.jpg'),
          items: [
            'Recrutamento e seleção de funcionários, com pesquisa cadastral',
            'Admissão e procedimentos de registro',
            'Contrato de experiência',
          ],
          description: `
<p>Fazer uma troca de bastão entre as gestões é fundamental para que haja tranquilidade -tanto para quem está chegando, como para quem está deixando o cargo.</p>
<p>Nesse momento, é muito saudável que haja uma conversa entre essas duas pessoas, para esclarecer qualquer dúvida que possa surgir, como relacionada a uma obra em andamento no condomínio. Além disso, na troca de gestão do síndico, o anterior deve entregar todos os documentos referentes à sua administração, principalmente:</p>
<ul>
  <li>Apólices de Seguro;</li>
  <li>Cartão de CNPJ;</li>
  <li>Folhas de Ponto de Funcionários;</li>
  <li>Laudos PCMSO/PPRA;</li>
  <li>Livro de Inspeção do Trabalho;</li>
  <li>Escritura de Convenção;</li>
  <li>Regulamento Interno; Livros de Atas de assembleia;</li>
  <li>Contratos(manutenção etc);</li>
  <li>Pastas de Prestações de Contas;</li>
</ul>`,
        },

        // INCORPORAÇÃO IMOBILIÁRIA
        {
          title: 'INCORPORAÇÃO IMOBILIÁRIA',
          icon: require('@/assets/features/007-house.png'),
          bg: require('@/assets/incorporacao_imobiliaria-1024x512.jpg'),
          items: [
            'Auxílio na instituição  de afetação',
            'Assistência na viabilidade de se construir garantias',
            'Suporte nos trâmites da Lei do distrato',
          ],
          description: `<p>A incorporação imobiliária é o conjunto de atividades voltadas a construir edificações ou grupos de edificações voltados para a alienação. Além da construção, a incorporação imobiliária também compreende a comercialização das unidades construídas, seja de forma parcial ou total.</p>
          <h2>Lei de incorporação imobiliária</h2>
          <p>A Lei do Condomínio e Incorporações ou Lei de incorporação imobiliária é a Lei n. 4.591, de 16 de dezembro 1964. Ela regula a atuação dos incorporadores, que constroem e promovem certas edificações.</p>`,
        },

        // ÁREA DO CLIENTE
        {
          title: 'ÁREA DO CLIENTE',
          icon: require('@/assets/features/010-online-payment.png'),
          bg: require('@/assets/doutores-da-web.png'),
          link: 'https://singularegestaoapp.com21.com.br/frontend/public/#/login',
          items: [
            'Acesso aos boletos',
            'Liberação de visitantes pelo celular',
            'Possibilidade de abrir chamados e solicitaçoes de forma digital',
          ],
          description: `
<p>Agora você pode aproveitar melhor seu tempo, sem deixar de lado as questões mais importantes do condomínio. Tenha na palma da mão as principais funcionalidades:</p>
<ul>
  <li>CAIXA LOCAL</li>
  <li>ENVIO E APROVAÇÃO DE PAGAMENTOS</li>
  <li>PANORAMA GERAL  DO CONDOMÍNIO</li>
  <li>CONSULTA DOS MORADORES</li>
  <li>RESERVA DAS ÁREAS COMUNS</li>
  <li>CONTROLE E GESTÃO DAS RECEITAS</li>
  <li>GESTÃO DE PESSOAS</li>
  <li>FALE CONOSCO</li>
</ul>
          `,
        },
      ],
    }),
    methods: {
      showFeature (f) {
        var vm = this
        vm.selected = f
        vm.dialog = true
      },
      openLink (link) {
        const win = window.open(link, '_blank')
        win.focus()
      },
    },
  }
</script>
